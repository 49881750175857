import { amsClient } from 'src/service/ams';
import badgesFilter from 'src/filters/expiredBadgesFilter';

class BadgesService {
  async getBadges() {
    return await amsClient.callAms('/shoprzapi/stores/badges/', {cache: false});
  }

  async getBadgesNames() {
    const badges = await this.getBadges();

    return badgesFilter(badges.items).map(item => ({
      name: item.title,
      id: item.id,
    }));
  }
}

const badges = new BadgesService();
export default badges
